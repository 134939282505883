<template>
  <div>
    <v-menu :close-on-content-click="false" v-model="showDatePicker" :nudge-right="40" transition="scale-transition"
            max-width="290px"
            min-width="290px">
      <template v-slot:activator="{ on }">
        <v-text-field v-mask="'##.##.####'"
                      style="display: inline-block; width: 49%; margin-right: 2%;"
                      :rules="[v=>(v==null||v.length===0||localValueToDateString(v)!=null)]"
                      v-model="dateValue"
                      @click:prepend-inner="on.click" filled outlined
                      @click:clear="clear"
                      :label="dateLabel" prepend-inner-icon="mdi-calendar-month-outline"
                      @input="dateTextFieldInput">
        </v-text-field>
      </template>
      <v-date-picker locale="ru-RU" :value="value && value.slice(0,10)" @input="pickerInput" :min="minDate" :max="maxDate">
      </v-date-picker>
    </v-menu>
    <v-menu :close-on-content-click="false" v-model="showTimePicker" :nudge-right="40" transition="scale-transition">
      <template v-slot:activator="{ on }">
        <v-text-field v-mask="'##:##'"
                      style="display: inline-block; width: 49%"
                      :rules="[v=>(v==null||v.length===0||validateTime(v))]"
                      v-model="timeValue"
                      @click:prepend-inner="on.click" filled outlined
                      prepend-inner-icon="mdi-clock-outline"
                      @click:clear="clear"
                      :label="timeLabel"
                      @input="timeTextFieldInput">
        </v-text-field>
      </template>
      <v-time-picker
          format="24hr"
          landscape
          :value = "timeValue"
          @input="timePickerInput"
      ></v-time-picker>
    </v-menu>
  </div>
</template>

<script>
import {customerTimeZoneDict} from "@/modules/NSI";

export default {
  name: "DateTimeField",
  props: {
    value: String,
    dateLabel: String,
    timeLabel: String,
    timeZone: {type: String, default: 'Europe/Moscow'},
    minDate: String,
    maxDate: String
  },
  data() {
    return {
      dateValue: '',
      timeValue: '',
      showDatePicker: false,
      showTimePicker: false,
      customerTimeZoneDict: customerTimeZoneDict
    };
  },
  methods: {
    toDateString(date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: this.timeZone, year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    },

    toTimeString(date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: this.timeZone, hour: '2-digit', minute: '2-digit'}
      return mdate.toLocaleTimeString('ru-RU', options)
    },

    //02.03.2022 -> 2022-03-02
    localValueToDateString(v) {
      if (v == null || v.length != 10)
        return null
      let res = v.charAt(6) + v.charAt(7) + v.charAt(8) + v.charAt(9) + '-' + v.charAt(3) + v.charAt(4) + '-' + v.charAt(0) + v.charAt(1)
      let date = new Date(res)
      if (isNaN(date) || date < new Date(this.minDate) || date > new Date(this.maxDate))
        return null
      return res
    },

    validateTime(v) {
      if (v.length !== 5) return false;
      if (v[2] !== ':') return false;
      if (!this.isNumberSymb(v[0]) || !this.isNumberSymb(v[1]) || !this.isNumberSymb(v[3]) || !this.isNumberSymb(v[4])) return false;
      const hour = Number(v[0] + v[1])
      const minute = Number(v[3] + v[4])
      if (hour < 0 || hour > 23) return false
      if (minute < 0 || minute > 59) return false
      return true
    },

    isNumberSymb(v) {
      return String(v) >= '0' && String(v) <= '9';
    },

    checkAndEmitDateTimeValue(){
      if(this.localValueToDateString(this.dateValue) && this.timeValue) {
        this.$emit('input', this.localValueToDateString(this.dateValue) + 'T' + this.timeValue + ':00' + this.getTimezoneOffset());
      } else {
        this.$emit('input', null);
      }
    },

    pickerInput(val) {
      this.showDatePicker = false;
      this.dateValue = this.toDateString(val);
      this.checkAndEmitDateTimeValue();
    },

    getTimezoneOffset() {
      return this.customerTimeZoneDict.find(e=>e.jsName === this.timeZone).offset + ':00';
    },

    timePickerInput(val){
      this.timeValue = val
      this.checkAndEmitDateTimeValue();
    },

    dateTextFieldInput() {
      this.checkAndEmitDateTimeValue();
    },

    timeTextFieldInput(val) {
      this.timeValue = this.validateTime(val) ? val : null;
      this.checkAndEmitDateTimeValue();
    },

    clear() {
      this.dateValue = ''
      this.timeValue = ''
      this.$emit('input', null)
    }
  },
  beforeMount() {
    this.dateValue = this.toDateString(this.value)
    this.timeValue = this.toTimeString(this.value)
  }
}
</script>

<style scoped>
</style>
