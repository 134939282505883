<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">

        <TenderEditComponent :set-section="setSection"/>

    </transition>
  </div>
</template>

<script>

import TenderEditComponent from '@/components/tenders/TenderEditComponent.vue'

export default {
  name: 'Tender',
  props: ['setSection'],
  components: {
    TenderEditComponent
  }
}
</script>

<style lang="scss">
@import "../styles/main.scss";
</style>

<style scoped lang="scss">
</style>
