<template>
  <v-container>

    <h1>
      <span>Редактирование тендера</span>
    </h1>

    <section class="form-wrap" v-if="apiLoaded">
      <v-form ref="form">

      <v-row>
        <v-col cols="12">
          <v-text-field
              label="Регистрационный номер"
              dense outlined
              v-model="tender.regnum"
              :rules="[
                  (v=>(v != null && v.length > 0) || 'Поле не должно быть пустым'),
                  (v=>(v && v.length<=64) ||'Максимальное число символов - 64')
              ]"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <register-select-field
              field-title="Заказчик"
              dialog-title="Выбор заказчика"
              :reg-config="customerRegisterConfig"
              page-size="10"
              default-tab="0"
              :text-function="item => item.fullName || ''"
              text-search-attribute="fullName"
              :value-object="tender.customer"
              @select="tender.customer = $event; $forceUpdate();"
              @clear="tender.customer = null; $forceUpdate();"
              not-null="true"
          >
          </register-select-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-textarea
              label="Тема"
              outlined
              v-model="tender.theme"
              :rules="[v=>(v==null||v.length===0||v.length<=4000||'Максимальное число символов - 4000')]"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-text-field
              label="Номер лота"
              dense outlined
              v-model="tender.lot"
              :rules="[v=>(v==null||v.length===0||!!normalizeNumber(v, 0, 1e13, 0)||'Введите корректный номер')]"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-text-field
              label="Тема лота"
              dense outlined
              v-model="tender.lotTheme"
              :rules="[v=>(v==null||v.length===0||v.length<=4000||'Максимальное число символов - 4000')]"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12"><h2>Общая информация</h2></v-col>
      </v-row>


      <v-row>
        <v-col cols="12">
          <v-text-field
              label="начальная цена"
              dense outlined
              v-model="tender.maxPrice"
              :rules="[v=>(v==null||v.length===0||!!normalizeNumber(v, 0, 1e13, 2)||'Введите корректную сумму')]"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-select label="процедура закупки"
                    :items="purchaseProceduresDict"
                    item-text="title" item-value="code"
                    dense outlined
                    v-model="tender.procedure"></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-select label="уровень"
                    readonly
                    :items="customerLevelsDict"
                    item-text="title" item-value="code"
                    dense outlined
                    :value="tender.customer && tender.customer.level"></v-select>
        </v-col>
      </v-row>


      <v-row>
        <v-col cols="12">
          <v-text-field label="субъект рф" dense outlined readonly :value="tender.customer && tender.customer.region && tender.customer.region.regionName"></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-text-field
              label="ссылка на тендер"
              dense outlined
              v-model="tender.url"
              :rules="[
                  (v=>(v != null && v.length > 0) || 'Поле не должно быть пустым'),
                  (v=>(v && v.length<=2000) ||'Максимальное число символов - 2000')
              ]"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12"><h2>Этапы</h2></v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <date-time-field
              date-label="Дата публикации" time-label="Время публикации"
              :value="tender.publishDateTime"
              @input="tender.publishDateTime=$event"
              :time-zone="getCustomerTimeZone()"
              min-date="2000-01-01" max-date="2029-12-31"></date-time-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <date-time-field
              date-label="Дата мониторинга" time-label="Время мониторинга"
              :value="tender.monDateTime"
              @input="tender.monDateTime=$event"
              time-zone="Europe/Moscow"
              min-date="2000-01-01" max-date="2029-12-31"></date-time-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <date-time-field
              date-label="Дата окончания подачи" time-label="Время окончания подачи"
              :value="tender.endDateTime"
              @input="tender.endDateTime=$event"
              :time-zone="getCustomerTimeZone()"
              min-date="2000-01-01" max-date="2029-12-31"></date-time-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <date-time-field
              date-label="Дата оценки первых частей" time-label="Время оценки первых частей"
              :value="tender.okAssess1DateTime"
              @input="tender.okAssess1DateTime=$event"
              :time-zone="getCustomerTimeZone()"
              min-date="2000-01-01" max-date="2029-12-31"></date-time-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <date-field
              label="Дата подачи вторых частей"
              :value="tender.okFinalEndDate"
              @input="tender.okFinalEndDate=$event"
              min-date="2000-01-01" max-date="2029-12-31"></date-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <date-time-field
              date-label="Дата оценки вторых частей" time-label="Время оценки вторых частей"
              :value="tender.okAssess2DateTime"
              @input="tender.okAssess2DateTime=$event"
              :time-zone="getCustomerTimeZone()"
              min-date="2000-01-01" max-date="2029-12-31"></date-time-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <date-field
              label="Дата аукциона"
              :value="tender.auctionDate"
              @input="tender.auctionDate=$event"
              min-date="2000-01-01" max-date="2029-12-31"></date-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"><h2>Обеспечение</h2></v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-text-field
              label="обеспечение заявки"
              dense outlined
              v-model="tender.appGuarantee"
              :rules="[v=>(v==null||v.length===0||!!normalizeNumber(v, 0, 1e13, 2)||'Введите корректную сумму')]"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
              label="%"
              dense outlined
              v-model="tender.appGuaranteePart"
              :rules="[v=>(v==null||v.length===0||!!normalizeNumber(v, 0, 100, 2)||'Введите корректную сумму')]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
              label="обеспечение контракта"
              dense outlined
              v-model="tender.contrGuarantee"
              :rules="[v=>(v==null||v.length===0||!!normalizeNumber(v, 0, 1e13, 2)||'Введите корректную сумму')]"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
              label="%"
              dense outlined
              v-model="tender.contrGuaranteePart"
              :rules="[v=>(v==null||v.length===0||!!normalizeNumber(v, 0, 100, 2)||'Введите корректную сумму')]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
              label="обеспечение гарантии"
              dense outlined
              v-model="tender.provisionWarranty"
              :rules="[v=>(v==null||v.length===0||!!normalizeNumber(v, 0, 1e13, 2)||'Введите корректную сумму')]"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
              label="%"
              dense outlined
              v-model="tender.provisionWarrantyPart"
              :rules="[v=>(v==null||v.length===0||!!normalizeNumber(v, 0, 100, 2)||'Введите корректную сумму')]"
          ></v-text-field>
        </v-col>
      </v-row>

<!--      <v-row>-->
<!--        <v-col cols="12"><h2>Результат</h2></v-col>-->
<!--      </v-row>-->

<!--      <v-row>-->
<!--        <v-col cols="12">-->
<!--          <v-text-field label="победитель" dense outlined></v-text-field>-->
<!--        </v-col>-->
<!--      </v-row>-->

<!--      <v-row>-->
<!--        <v-col cols="12">-->
<!--          <v-text-field label="сумма" dense outlined></v-text-field>-->
<!--        </v-col>-->
<!--      </v-row>-->


      <div class="mt-6">
        <v-btn outlined @click="cancel()">Отменить</v-btn>
        <v-btn @click="submit()">Сохранить изменения</v-btn>
      </div>

      </v-form>
    </section>

  </v-container>
</template>


<script>
import api from '../../modules/api'
import RegisterSelectField from '../register/RegisterSelectField.vue';

import {counterpartiesRegisterConfig} from "@/components/register/RegisterConfigs";
import DateTimeField from "@/components/elements/DateTimeField";
import {customerLevelsDict, customerTimeZoneDict, purchaseProceduresDict} from "@/modules/NSI";
import DateField from "@/components/elements/DateField";
import {deleteNullFields, normalizeNumber} from "@/modules/CommonUtils";

export default {
  name: 'TenderEditComponent',
  props: ['setSection'],
  components: {RegisterSelectField, DateTimeField, DateField},
  data() {
    return {
      tenderId: null,
      tender: {},
      apiError: false,
      apiLoaded: false,
      customerTimeZoneDict: customerTimeZoneDict,
      customerRegisterConfig: counterpartiesRegisterConfig,
      purchaseProceduresDict: purchaseProceduresDict,
      customerLevelsDict: customerLevelsDict
    }
  },
  methods: {
    normalizeNumber: normalizeNumber,
    cancel() {
      if (this.tender.id != null) {
        this.$router.push({path: '/tender/' + this.tender.id + '/info?type=supmain/notifications'})
      } else {
        this.$router.push({path: '/tenders/'})
      }
    },
    async submit() {
      if(!this.$refs.form.validate()) return;
      this.tender.maxPrice = normalizeNumber(this.tender.maxPrice, 0, 1e13, 2);
      this.tender.lot = normalizeNumber(this.tender.lot, 0, 1e13, 2);
      this.tender.appGuarantee = normalizeNumber(this.tender.appGuarantee, 0, 1e13, 2);
      this.tender.contrGuarantee = normalizeNumber(this.tender.contrGuarantee, 0, 1e13, 2);
      this.tender.provisionWarranty = normalizeNumber(this.tender.provisionWarranty, 0, 1e13, 2);
      this.tender.appGuaranteePart = normalizeNumber(this.tender.appGuaranteePart, 0, 1e13, 2);
      this.tender.contrGuaranteePart = normalizeNumber(this.tender.contrGuaranteePart, 0, 1e13, 2);
      this.tender.provisionWarrantyPart = normalizeNumber(this.tender.provisionWarrantyPart, 0, 1e13, 2);
      this.tender.monDateTime = this.tender.monDateTime ? this.removeTimeZoneFromDateStr(this.tender.monDateTime) : null
      this.tender = deleteNullFields(this.tender, true);
      //console.log(this.tender)
      let req;
      if(this.tenderId) req = await api.putJson('/supmain/notifications/', this.tender)
      else req = await api.postJson('/supmain/notifications/', this.tender)
      console.log(req)
      if (req.ok) {
        this.$router.push({path: '/tender/' + req.payload.id + '/info?type=supmain/notifications'})
      } else {
        alert('Что-то пошло не так')
      }
    },

    //2022-08-21T22:02:00+03:00 -> 2022-08-21T22:02:00
    removeTimeZoneFromDateStr(dateStr){
      let plusIndex = dateStr.indexOf('+');
      if(plusIndex > 0) return dateStr.slice(0, -6)
      return dateStr;
    },

    async loadData() {
      let req = await api.get('supmain/notifications/' + this.tenderId)
      if (req.ok) {
        this.tender = req.payload
      } else {
        this.apiError = true
      }
      this.apiLoaded = true
      console.log(this.tender)
    },

    getCustomerTimeZone() {
      if(this.tender.customer == null) return undefined;
      return customerTimeZoneDict.filter(e => e.code === this.tender.customer.customerTimeZone)?.[0]?.jsName;
    }
  },
  async beforeMount() {
    this.setSection('TENDERS')
    this.tenderId = this.$route.params.id
    if(this.tenderId) await this.loadData()
    else this.apiLoaded = true
  }
}
</script>
